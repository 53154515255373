<template>
  <div class="assessmentPlan">
    <div class="shadowSize baseInfo">
      <base-info :infoKeys="infoKeys" :infoValue="infoValue"></base-info>
    </div>
    <div class="shadowSize mar_top_36 planTable">
      <a-table
        bordered
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :row-key="(record,index) =>index"
        :loading="searchBtnLoading">
        <a slot="name" slot-scope="text">{{ text }}</a>
        <template slot="planOperation" slot-scope="text, record">
          <div v-for="(item,index) in record.plan" :key="index">
              <p v-for="(subItem,indey) in item" :key="indey">{{subItem}}</p>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import baseInfo from '@/components/content/baseInfo'
import { getplan } from '@/api/record'
import { stampToTime } from 'utils/index'
export default {
  name: 'vbAssessmentPlan',
  components: {
    baseInfo
  },
  data () {
    return {
      searchBtnLoading: false,
      columns: [{
        title: '训练领域',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: '120px'
      },
      {
        title: '训练目标',
        dataIndex: 'mil_name',
        key: 'mil_name',
        align: 'center',
        width: '120px'
      },
      {
        title: '训练计划详细内容',
        dataIndex: 'plan',
        key: 'plan',
        scopedSlots: {
          customRender: 'planOperation'
        }
      }
      ],
      infoKeys: ['孩子姓名', '出生日期', '测评老师', '测评记录', '评估日期', '评估得分'],
      infoValue: {},
      data: [],
      mobile: this.$route.query.mobile
    }
  },
  mounted () {
    this.getPlanList()
  },
  methods: {
    async getPlanList () {
      this.searchBtnLoading = true
      const res = await getplan(this.mobile)
      if (res.code !== '1') {
        return this.$message.error(res.msg)
      }
      this.searchBtnLoading = false
      this.infoValue = {
        child_name: res.data.child_name,
        birth: stampToTime(res.data.birth, 'YYYY-MM-DD'),
        tea_name: res.data.tea_name,
        num: '第' + res.data.num + '次',
        assess_time: stampToTime(res.data.assess_time, 'YYYY-MM-DD'),
        score: res.data.score + '分'
      }
      this.data = res.data.list
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .ant-table-thead > tr > th {
  background: #ffbe27;
}
.statement {
  padding: 16px 51px 30px 19px;
  strong {
    display: block;
    font-size: 16px;
    color: var(--color-tint);
    font-weight: normal;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #666666;
    margin: 0;
  }
}
.backBtn {
  margin: 50px 0 70px;
  text-align: center;
  overflow: hidden;
  .ant-btn {
    width: 140px;
    height: 44px;
    background: var(--color-tint);
    color: #fff;
    border: none;
  }
}
.baseInfo {
  ::v-deep li:nth-child(4) span:nth-child(1)::before {
    background-position: -113px 0;
  }
  ::v-deep li:nth-child(5) span:nth-child(1)::before {
    background-position: -137px 0;
  }
  ::v-deep li:nth-child(6) span:nth-child(1)::before {
    background-position: -160px 0;
  }
}
::v-deep .ant-table-thead tr th:nth-child(3) {
  text-align: center;
}
p {
  margin: 0;
}
</style>
