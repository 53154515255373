np<template>
<div>
  <div style="overflow: hidden;">
    <span class="backIcon" @click="backChange">〈 返回</span>
    <a-button class="previewBtn" @click="previewChange">预览<a-icon type="search" /></a-button>
  </div>
    <h3 class="title">星希望VB-MAPP评估报告总览</h3>
    <div class="assessment_tit">
      <span v-for="(item,index) in assessment" :key="index" :class="curIndex==index?'cur':''" @click="assessmentChange(index)">{{item}}</span>
    </div>
    <!-- <keep-alive> -->
    <component v-bind:is="recode[curIndex]"></component>
    <!-- </keep-alive> -->
    <vb-back @backChange="backChange" v-show="isBack">返回操作</vb-back>
</div>
</template>
<script>
import assessmentRecord from './component/assessmentRecord.vue'
import assessmentResult from './component/assessmentResult.vue'
import assessmentPlan from './component/assessmentPlan.vue'
import curriculumProposal from './component/curriculumProposal.vue'
import vbBack from '@/components/common/vb-back'
export default {
  name: 'evaluatingDetail',
  data () {
    return {
      curIndex: this.$route.query.curIndex,
      assessment: ['评估记录', '评估结果', '训练计划', '排课建议'],
      mobile: this.$route.query.mobile,
      isBack: true
    }
  },
  components: { assessmentRecord, assessmentResult, assessmentPlan, curriculumProposal, vbBack },
  computed: {
    recode () {
      return ['assessmentRecord', 'assessmentResult', 'assessmentPlan', 'curriculumProposal']
    }
  },
  watch: {
    curIndex (newVal) {
      if (newVal === 3) {
        this.isBack = false
      } else {
        this.isBack = true
      }
    }
  },
  mounted () {

  },
  methods: {
    assessmentChange (index) {
      this.curIndex = index
    },
    previewChange () {
      console.log(this.curIndex)
      this.$router.push({ path: '/previewLayout', query: { curIndex: this.curIndex, mobile: this.mobile } })
    },
    backChange () {
      console.log('a')
      this.$router.push('/home')
    }
  },
  curIndex (newVal) {
    console.log('newVal', newVal)
    this.curIndex = this.$route.query.id
  }
}
</script>
<style lang="less" scoped>
.previewBtn{border:none;background: #FF5858; color: #fff;float:right;}
.title{font-size:30px ; color: #333; text-align:center; margin-bottom: 42px;}
.assessment_tit{
   width: 615px;display: flex;
    justify-content: space-between; margin: 0 auto 32px;
  >span{padding:9px 32px;font-size: 18px;border:1px solid var(--color-tint) ;border-radius: 20px; cursor: pointer;}
  .cur{
    background: var(--color-tint); color: #fff; position: relative;
    >::before{content: '';width: 60px; height: 30px; background:  var(--color-tint); position: absolute; }
  }
   .cur::before{content: ''; position: absolute; width: 0;
    height: 0;
        border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid var(--color-tint);
    bottom: -11px;
    left: 56px;}
}
.previewBtn_item{ overflow: hidden;}
.backIcon{cursor: pointer; font-size: 18px;}
</style>
