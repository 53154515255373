<template>
  <div class="assessmentResult">
    <div class="shadowSize baseInfo">
        <base-info :infoKeys="infoKeys" :infoValue="infoValue"></base-info>
    </div>
    <div class="shadowSize mar_top_36" style="padding-bottom:30px">
      <vb-radar-chart class="mainChart" v-if="chartShow" :radarChartDate="radarChartDate"></vb-radar-chart>
    </div>
    <div class="shadowSize mar_top_36" style="padding-bottom:30px">
      <vb-line-charts class="mainChart" v-if="chartShow" :lineChartDate="lineChartDate"></vb-line-charts>
    </div>
    <!-- <div class="shadowSize mar_top_36 caseInfo">
      <div>
        <strong>评估结论</strong>
      </div>
      <p>
        VB-MAPP里程碑评估包括提要求、命名、听者反应等16类学习和语言技能，根据正常孩子的发展进程，里程碑被划分为三个阶段（0~18个月、18~30个月和30~48个月）。<br /> <span style="color:#FF8B3D">{{infoValue.child_name}}</span>在此次VB-MAPP里程碑评估中得{{infoValue.score}}（总分为170分）。（第一阶段0~45分、第二阶段46~105分、第三阶段106~170分）。报告对被评估的技能做单项分析，并为其制定相应的教学训练方案。</p>
    </div> -->
    <div class="shadowSize mar_top_36 resultTable">
      <a-table :columns="columns" :data-source="dataResult" :pagination="false" :row-key="(record,index) =>index">
          <a slot="name" slot-scope="text">{{ text }}</a>
      </a-table>
    </div>
  </div>
</template>
<script>
import baseInfo from '@/components/content/baseInfo'
import vbLineCharts from '@/components/content/charts/lineChart'
import vbRadarChart from '@/components/content/charts/radarChart'
import { GetAssessmentResult } from '@/api/record'
import { stampToTime } from 'utils/index'
import { mapMutations } from 'vuex'
export default {
  name: 'assessmentResult',
  data () {
    return {
      columns: [{
        title: '领域',
        dataIndex: 'name',
        align: 'center',
        key: 'name',
        width: '120px'
      },
      {
        title: '阶段',
        dataIndex: 'stage_name',
        align: 'center',
        key: 'stage_name',
        width: '100px'
      },
      {
        title: '里程碑',
        dataIndex: 'plan_num',
        align: 'center',
        key: 'plan_num',
        width: '100px',
        customRender: text => {
          return text + 'M'
        }
      },
      {
        title: '得分',
        dataIndex: 'fraction',
        align: 'center',
        key: 'fraction',
        width: '70px'
      },
      {
        title: '总分',
        dataIndex: 'total_score',
        align: 'center',
        key: 'total_score',
        width: '70px'
      },
      {
        title: '孩子优势',
        dataIndex: 'merit',
        key: 'merit',
        width: '370px'
      },
      {
        title: '不足及训练建议',
        dataIndex: 'defect',
        key: 'defect',
        width: '370px'
      }
      ],
      dataResult: [],
      infoKeys: ['孩子姓名', '出生日期', '测评老师', '测评记录', '评估日期', '评估得分'],
      infoValue: {},
      lineChartDate: [],
      radarChartDate: [],
      mobile: this.$route.query.mobile,
      chartShow: false,
      oneTable: [],
      twoTable: [],
      threeTable: []
    }
  },
  components: {
    baseInfo,
    vbLineCharts,
    vbRadarChart
  },
  mounted () {
    this.getAssessmentResult()
  },
  methods: {
    ...mapMutations(['getChildrenInfo', 'getChartDateInfo', 'getOneTableResult', 'getTwoTableResult', 'getThreeableResult']),
    transformChart (stage) {
      const target = []
      const num = [1, 2, 3, 4, 5, 6]
      stage.forEach(item => {
        num.forEach((item2, index) => {
          if (Number(item.num) === item2) {
            const evaluation = {
              require: parseFloat(item.require),
              nominate: parseFloat(item.nominate),
              reaction: parseFloat(item.reaction),
              pair: parseFloat(item.pair),
              game: parseFloat(item.game),
              contact: parseFloat(item.contact),
              imitate: parseFloat(item.imitate),
              echolalia: parseFloat(item.echolalia),
              sound: parseFloat(item.sound),
              read: parseFloat(item.read),
              write: parseFloat(item.write),
              lrffc: parseFloat(item.lrffc),
              talk: parseFloat(item.talk),
              team: parseFloat(item.team),
              structure: parseFloat(item.structure),
              mathema: parseFloat(item.mathema)
            }
            const evaluationValue = Object.values(evaluation)
            target.push(evaluationValue)
            console.log('target', target)
          }
        })
      })
      return target
    },
    async getAssessmentResult () {
      const res = await GetAssessmentResult(this.mobile)
      // const { data: res } = await axios.get('https://mock.mengxuegu.com/mock/60a1e2d9c7b7385be0a83aac/example/vb-mappdetail/result')
      // console.log(res)
      if (res.code !== '1') {
        return this.$message.error(res.msg)
      }
      this.infoValue = {
        child_name: res.data.child_name,
        birth: stampToTime(res.data.birth, 'YYYY-MM-DD'),
        tea_name: res.data.tea_name,
        num: '第' + res.data.num + '次',
        assess_time: stampToTime(res.data.assess_time, 'YYYY-MM-DD'),
        score: res.data.score + '分'
      }
      this.lineChartDate = this.transformChart(res.data.chart_data)
      this.radarChartDate = this.transformChart(res.data.chart_data)
      console.log('lineChartDate', res.data.chart_data)

      this.chartShow = true
      this.dataResult = res.data.scheme_list
      console.log('dataResult', this.dataResult)
      const valueChild = {
        ...this.infoValue,
        num: res.data.num
      }
      this.getChildrenInfo(valueChild)
      this.getChartDateInfo(this.lineChartDate)
      // this.radarChartDateInfo(this.radarChartDate)
      const tableData = Object.assign([], res.data.scheme_list)
      const index = tableData.length
      if (index > 0) {
        this.oneTable.push(...tableData.slice(0, 5))
      }
      if (index > 5) {
        this.twoTable.push(...tableData.slice(5, 10))
      }
      if (index > 10) {
        this.threeTable.push(...tableData.slice(10, 16))
      }
      // this.oneTable.push(...tableData.slice(0, 7))
      // this.twoTable.push(...tableData.slice(7, 13))
      // this.threeTable.push(...tableData.slice(7, 13))
      this.getOneTableResult(this.oneTable)
      this.getTwoTableResult(this.twoTable)
      this.getThreeableResult(this.threeTable)
    }

  }
}
</script>
<style lang='less' scoped>
::v-deep .ant-table-thead > tr > th{background: #FFBE27;}
.mainChart{ padding-top:27px}
.mar_top_36{margin-top: 36px;}
.caseInfo{
  padding:19px 38px 11px;
  >div{
    margin-bottom: 18px;
    strong{font-size: 20px; border-bottom:2px solid var(--color-tint);font-weight: normal;padding-bottom:7px}
  }
  >p{font-size: 14px; line-height: 28px; color:#666}
}
.baseInfo{
  ::v-deep li:nth-child(4) span:nth-child(1)::before{
   background-position:-113px 0;
 }
 ::v-deep li:nth-child(5) span:nth-child(1)::before{
   background-position:-137px 0;
 }
 ::v-deep li:nth-child(6) span:nth-child(1)::before{
   background-position:-160px 0;
 }
}
 ::v-deep .ant-table-thead tr th:nth-child(6){
  text-align: center;
}
::v-deep .ant-table-thead tr th:nth-child(7){
  text-align: center;
}

</style>
