<template>
  <div class="curriculumProposal">
    <a-form-model :model="form" ref="form" :rules="rules" layout="inline">
      <div class="proposalItem">
        <h3 class="title">
          <span class="iconLine">评估小结</span>
        </h3>
        <div class="proposalItemInfo">
          <p>VB-MAPP里程碑评估包括提要求、命名、听者反应等16类学习和语言技能，根据正常孩子的发展进程，里程碑被划分为三个阶段（0~18个月、18~30个月和30~48个月）。</p>
          <p>
            <i class="colorName">{{form.child_name}}</i>

            在此次VB-MAPP里程碑评估中得{{score}}分（总分为170分）。目前相当于正常孩子{{conclusion}}的水平（第一阶段0~45分、第二阶段46~105分、第三阶段106~170分）。报告对被评估的技能做单项分享，并推荐相应的教学训练方案。
          </p>
        </div>
      </div>
      <div class="proposalItem">
        <a-form-model-item class="summaryInfo" prop="summary" style="padding-left: 22px">
          <a-input
            v-model.trim="form.summary"
            type="textarea"
            placeholder="请详细描述..."
            class="textareaInput"
            :maxLength="1000"
          />
          <!-- :maxLength="850" -->
          <span class="total">({{total}}/1000)</span>
        </a-form-model-item>
      </div>
      <div class="proposalItem">

        <h3 class="title">
          <span class="iconLine">排课建议</span>
        </h3>
        <div class="childrenInfo" style="padding-left:16px">
          <span style="padding-left:16px">
            <i class="colorName">{{form.child_name}}</i> 能力目前属于星希望
          </span>
          <a-form-model-item prop="class">

            <a-select
              class="selectBox"
              v-model="form.class"
              :options="classOptions"
              placeholder="请选择班级"
            ></a-select>
          </a-form-model-item>
          <span>班 第</span>
          <a-form-model-item prop="grade">
            <a-select
              class="selectBox"
              v-model="form.grade"
              :options="gradeOptions"
              placeholder="请选择年级"
            ></a-select>
          </a-form-model-item>
          <span>级</span>
        </div>
        <div class="proposalItemInfo">
          <div>
            <a-form-model-item label="训练时长" class="selectClass" prop="month">
              <a-select
                v-model="form.month"
                :options="monthOptions"
                class="selectBoxIn"
                placeholder="请选择训练时长"
              ></a-select>
            </a-form-model-item>
          </div>
          <div>
            <a-form-model-item label="训练时间" prop="cycle">
              <a-checkbox-group v-model="form.cycle" :options="timeOptions" placeholder="请选择训练时间"></a-checkbox-group>
            </a-form-model-item>
          </div>
          <div>
            <a-form-model-item label="训练课程" class="selectClass" prop="train">
              <a-select
                v-model="form.train"
                :options="trainOptions"
                class="selectBoxIn"
                placeholder="请选择个训"
              ></a-select>
              <span style="font-size:16px">节个训</span>
            </a-form-model-item>
            <a-form-model-item  class="selectClass" prop="muscle">
              <a-select
                v-model="form.muscle"
                class="selectBoxIn"
                :options="trainOptions"
                placeholder="请选择口肌"
              ></a-select>
              <span style="font-size:16px">节口肌</span>
            </a-form-model-item>
            <a-form-model-item  class="selectClass" prop="function">
              <a-select
                v-model="form.function"
                class="selectBoxIn"
                :options="trainOptions"
                placeholder="请选择职能"
              ></a-select>
              <span style="font-size:16px">节职能</span>
            </a-form-model-item>
            <a-form-model-item  class="selectClass" prop="close">
              <a-select
                v-model="form.close"
                class="selectBoxIn"
                :options="teamOptions"
                placeholder="请选择合训"
              ></a-select>
              <span style="font-size:16px">节合训</span>
            </a-form-model-item>
            <a-form-model-item  class="selectClass" prop="team">
              <a-select
                v-model="form.team"
                class="selectBoxIn"
                :options="teamOptions"
                placeholder="请选择集体"
              ></a-select>
              <span style="font-size:16px">节集体</span>
            </a-form-model-item>
            <a-form-model-item  class="selectClass" prop="parent">
              <a-select
                v-model="form.parent"
                class="selectBoxIn"
                :options="teamOptions"
                placeholder="请选择家长训练"
              ></a-select>
              <span style="font-size:16px;line-height:40px">节家长训练/天</span>
            </a-form-model-item>

          </div>
        </div>
      </div>
    </a-form-model>
    <vb-back @backChange="submitChange">保存</vb-back>
  </div>
</template>
<script>
import vbBack from '@/components/common/vb-back'
import { getClassideaList, addClassideaList } from '@/api/record'
import { mapState } from 'vuex'
export default {
  name: 'curriculumProposal',
  components: { vbBack },
  data () {
    return {
      classOptions: [
        { label: '语萌', value: '1' },
        { label: '语进', value: '2' },
        { label: '语达', value: '3' },
        { label: '幼融', value: '4' },
        { label: '学业', value: '5' }
      ],
      gradeOptions: [
        { label: '一', value: '1' },
        { label: '二', value: '2' },
        { label: '三', value: '3' }
      ],
      trainOptions: [
        { label: '0', value: '0' },
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
        { label: '13', value: '13' },
        { label: '14', value: '14' },
        { label: '15', value: '15' }
      ],
      teamOptions: [
        { label: '0', value: '0' },
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' }
      ],
      monthOptions: [
        { label: '1周', value: '1' },
        { label: '1个月', value: '2' },
        { label: '3个月', value: '3' },
        { label: '6个月', value: '4' },
        { label: '12个月', value: '5' }
      ],
      form: {

        class: '',
        grade: '',
        summary: '',
        month: '',
        cycle: [],
        train: '',
        team: ''

      },
      timeOptions: [
        { label: '周一', value: '1' },
        { label: '周二', value: '2' },
        { label: '周三', value: '3' },
        { label: '周四', value: '4' },
        { label: '周五', value: '5' },
        { label: '周六', value: '6' },
        { label: '周日', value: '7' }
      ],
      conclusion: '',
      score: ''
    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    async submitChange () {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return this.$message.info('请填必填项！')
        }
        const data = {
          ...this.form,
          num: this.childrenInfo.num,
          cycle: JSON.stringify(this.form.cycle),
          mobile: this.$route.query.mobile
        }
        // this.form.cycle =
        const res = await addClassideaList(data)
        if (res.code !== '1') {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
      })
    },
    async getInfo () {
      const res = await getClassideaList(this.$route.query.mobile)
      if (res.code !== '1') {
        return this.$message.error(res.msg)
      }
      this.conclusion = res.data.content
      this.score = res.data.total_score
      this.form = {
        child_name: res.data.child_name,
        class: res.data.class,
        grade: res.data.grade,
        summary: res.data.summary,
        month: res.data.month,
        cycle: res.data.cycle,
        train: res.data.train,
        team: res.data.team,
        muscle: res.data.muscle,
        function: res.data.function,
        parent: res.data.parent,
        close: res.data.close
      }
    }
  },
  computed: {
    ...mapState(['childrenInfo']),
    rules () {
      return {
        class: [{ required: true, message: '请选择孩子班级!', trigger: 'change' }],
        grade: [{ required: true, message: '请选择孩子年级!', trigger: 'change' }],
        summary: [
          { required: true, message: '评估小结不能为空', trigger: 'blur' }
        ],
        month: [{ required: true, message: '训练时长不能为空', trigger: 'change' }],
        cycle: [{ required: true, message: '训练时间不能为空!', trigger: 'change' }],
        train: [
          { required: true, message: '训练课程个训不能为空!', trigger: 'change' }
        ],
        team: [
          { required: true, message: '训练课程集体不能为空!', trigger: 'change' }
        ],
        muscle: [
          { required: true, message: '训练课程口肌不能为空!', trigger: 'change' }
        ],
        function: [
          { required: true, message: '训练课程职能不能为空!', trigger: 'change' }
        ],
        close: [
          { required: true, message: '训练课程合训不能为空!', trigger: 'change' }
        ],
        parent: [
          { required: true, message: '训练课程家长训练不能为空!', trigger: 'change' }
        ]

      }
    },
    total () {
      return (this.form.summary) ? this.form.summary.length : 0
    }

  }
}
</script>
<style lang="less" scoped>
::v-deep .selectBox {
  .ant-select-selection {
    width: 100px;
  }

}
::v-deep .ant-select-selection-selected-value {
    color: var(--color-tint);
  }
.childrenInfo {
  line-height: 38px;
  display: flex;
  font-size: 16px;
  > span {
    padding: 0 10px;
  }
  ::v-deep .ant-form-item {
    margin-right: 0;
  }
}
.colorName {
  color: var(--color-tint);
  font-style: normal;
}
.title {
  font-size: 22px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  > span {
    padding: 0 10px;
    padding-bottom: 2px;
  }
}
.iconLine {
  background: url('../../../assets/images/line.png') repeat-x left bottom;
}
.proposalItem {
  padding: 0 40px;
  .title {
    font-size: 20px;
    text-align: left;
  }
  > .proposalItemInfo {
    width: 100%;
    position: relative;
    padding-left: 22px;
    font-size: 16px;
    margin-bottom: 20px;
    > p {
      line-height: 28px;
      margin: 0;
    }
    > div {
      margin-bottom: 10px;
    }
  }
  .summaryInfo {
    width: 100%;
    margin-bottom: 20px;
    ::v-deep .ant-form-item-control-wrapper {
      width: 100%;
    }
  }
  .textareaInput {
    height: 288px;
    font-size: 16px;
    border: 1px solid #bebebe;
    border-radius: 18px;
    resize: none;
    padding: 14px;
  }
  .total {
    position: absolute;
    right: 21px;
    bottom: -6px;
  }
}
.selectClass {
  // width: 100%;
  margin-right: 0;
  ::v-deep .ant-form-item-control {
    display: inline-block;

    .ant-form-item-children {
      .selectBoxIn {
        width: 84px;
      }
      > * {
        display: inline-block;
      }
      > span {
        padding: 0 10px;
      }
    }
  }
}
::v-deep .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-tint);
  border-color: var(--color-tint);
}
::v-deep .ant-select-selection-selected-value {
  width: 100%;
  text-align: center;
}
::v-deep .ant-form label {
  font-size: 16px;
}
</style>
